.progress-container {
    position: relative;
    width: 80px;
    height: 80px;
  }
  .svg {
    transform: rotate(-90deg);
  }
  .progress-ring__circle {
    transition: stroke-dashoffset 0.6s ease-in-out;
  }
  .percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
    color: #1040ef;
  }