.open-btn {
    position: fixed;
    bottom: 152px;
    right: 20px;
    background-color: #ffffffab;
    color: #fff;
    /* padding: 12px 18px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 64px;
    height: 56px;
  }
  
  /* Modal container styles */
  .customer-support-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 320px;
    max-height: 426px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    z-index: 10000;
  }
  
  /* Header of the modal */
  .customer-support-header {
    background-color: #007bff;
    color: #fff;
    /* padding: 10px 15px; */
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Close button in the header */
  .close-btn {
    background: transparent;
    border: none;
    font-size: 2.2rem;
    color: #fff;
    cursor: pointer;
  }
  
  /* Body of the modal */
  .customer-support-body {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    font-size: 0.95rem;
    color: #333;
  }
  
  /* Footer area for the input and send button */
  .customer-support-footer {
    display: flex;
    padding: 1px;
    border-top: 1px solid #eee;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .feedback_input-group label{
    color: black;
  }
  
  .customer-support-footer input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .customer-support-footer button {
    margin-left: 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  .feedback_contact-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 323px;
    text-align: center;
    padding-left: 6px;
    /* overflow-y: scroll; */
}
.feedback_contact-container h2 {
    margin-bottom: 10px;
    color: #007FFF;
}
.feedback_contact-container p {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}
.feedback_input-group {
    text-align: left;
    margin-bottom: 15px;
}
.feedback_input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}
.feedback_input-group input {
    width: 88%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}
.feedback_input-group textarea{
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    height: 108px;
}
.feedback_button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}
.feedback_button:hover {
    background: #0056b3;
}
@media only screen and (max-width: 480px) {

.open-btn {
    position: fixed;
    bottom: 80px;
    background-color: #ffffffab;
    color: #fff;
    /* padding: 12px 18px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 64px;
    height: 56px;
    left: 14px;
    width: 57px;
}
.customer-support-modal {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 320px;
    max-height: 426px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    z-index: 10000;
}
}
