
  
  .chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .chat-button {
    background-color: #0078ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
  
  .chat-button:hover {
    background-color: #005fcc;
  }
  
  .chat-popup {
    position: fixed;
    bottom: 69px;
    left: 8%; 
    /* right: 20px; */
    width: 300px;
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .chat-popup.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  
  .chat-popup.closing {
    transform: translateY(100%);
    opacity: 0;
  }
  
  .chat-header {
    background-color: #0078ff;
    color: white;
    padding: 15px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-header .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .chat-body {
    padding: 15px;
    height: 200px;
    overflow-y: scroll;
    background: #f9f9f9;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 42px;
  }
  
  .message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
  }
  
  .message.sender {
    background-color: #0078ff;
    color: white;
    align-self: flex-end;
  }
  
  .message.receiver {
    background-color: #9a9ea9;
    align-self: flex-start;
  }
  
  .chat-footer {
    padding: 10px;
    background: #f1f1f1;
    display: flex;
    gap: 10px;
  }
  
  .chat-footer input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-footer button {
    background-color: #0078ff;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-footer button:hover {
    background-color: #005fcc;
  }
  