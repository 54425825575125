.share_card {
    margin-top: 29px;
    background: #fff;
    width: 397px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  background: linear-gradient(to right, #eb2e2e, #ffffff, #ffc65c);
  box-shadow:red 0px 0px 0px 2px inset, rgb(255, 85, 85) 10px -10px 0px -3px, rgb(255 0 0 / 54%) 10px -10px;
  animation: scaleAnimation 2s infinite alternate;
  
  }
  @keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}
  .share_img{
        align-items: baseline;
  justify-content: center;
  display: flex;
  
  }
  
  .share_logo{
     flex-direction:column;
  }
  .card:hover {
    transform: scale(1.05);
  }
  .share_card img {
    width: 100%;
    height: auto;
  }
  .share_card-content {
    padding: 20px;
    text-align: center;
    padding-top: 0px;
  }
  .share_card-content h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .share_card-content p {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px;
  }
  .share_card-content a {
    text-decoration: none;
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    display: inline-block;
    transition: background 0.3s;
  }
  .share_card-content a:hover {
    background: #0056b3;
  }
  @media (max-width: 400px) {
    .share_card {
        width: 90%;
    }
  }
  
  
  .power_off_text {
    color: transparent;
    background:linear-gradient(90deg, white 0%, #1A1AFF 20%);
    background-size: 200% 100%;
    background-clip: text;
    animation: slideEffect 5s infinite linear;
    -webkit-animation: slideEffect 5s infinite linear;
    font-size: 20px;
    line-height: 27px
}
@keyframes slideEffect {
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: -100% 0%;
    }
}