
  
  /* Toaster Container */
  .toaster-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }
  
  /* Toaster Notification */
  .toast {
    background-color: #323232;
    color: #fff;
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateY(-20px);
    animation: slide-in 0.5s forwards, fade-out 0.5s 8.5s forwards;
  }
  
  /* Slide-in animation */
  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Fade-out animation */
  @keyframes fade-out {
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
 