.modal-container_profile2 {
  position: relative;
}

.model_profile_img2 {
  transition: transform 0.3s ease;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
}

.modal-trigger_profile2 {
  position: fixed;
  top: 37%;
  right: 48%;
  width: 70px;
  height: 70px;
  z-index: 0;
  cursor: pointer;
  /* z-index: 1000; */
  transition: transform 0.3sease;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: white;
}
/* .modal-trigger_profile2 {
  position: fixed;
  top: 22%;
  right: 50%;
  width: 70px;
  height: 70px;
  z-index: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
} */

.modal-trigger_profile2:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.modal-trigger_profile23 {
  position: fixed;
  top: 29%;
  right: 48%;
  width: 70px;
  height: 70px;
  z-index: 0;
  cursor: pointer;
  /* z-index: 1000; */
  transition: transform 0.3s ease;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.modal-trigger_profile23:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.modal-overlay_profile2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modal-content_profile2 {
  position: fixed;
  top: calc(20px + 40px + 10px);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-out;
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  top: 30%;
}

.modal-arrow_profile2 {
  position: absolute;
  top: -8px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
}

/* Water Drop Animation */
@keyframes waterDrop {
  0% {
    transform: scale(0) translateY(-20px);
    opacity: 0;
  }

  80% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.modal-content_profile2 {
  animation: waterDrop 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Ripple Effect */
@keyframes ripple {
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.modal-overlay_profile2::before {
  content: '';
  position: absolute;
  top: calc(20px + 40px);
  right: 20px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 0.6s ease-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-trigger_profile2 {
    right: 45%;
    top: 33%;
    width: 50px;
    height: 50px;
    z-index: 0;
  }

  .modal-trigger_profile23 {
    right: 44%;
    top: 24%;
    width: 50px;
    height: 50px;
  }

  .modal-content_profile2 {
    right: 9px;
    left: 0px;
    width: calc(100% - 20px);
    max-width: none;
    top: 80px;
    /* margin-top: 60px; */
  }

  .modal-overlay_profile2::before {
    top: calc(15px + 35px);
    right: 15px;
  }
}

@media (max-width: 480px) {
  .modal-content_profile2 {
    padding: 15px;
    border-radius: 15px;
  }

  .modal-arrow_profile2 {
    right: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid rgba(255, 255, 255, 0.9);
  }
}

/* Optional: Add water-like texture */
.modal-content_profile2::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.1) 100%);
  pointer-events: none;
  border-radius: 20px;
  z-index: -1;
}

.avatar-upload2 {
  position: relative;
  max-width: 205px;
  margin: 16px auto;
}

.avatar-upload2 .avatar-edit {
  position: absolute;
  right: 53px;
  z-index: 1;
  top: 10px;
}

.avatar-upload2 .avatar-edit input {
  display: none;
}

.avatar-upload2 .avatar-edit input+label {
  display: inline-block;
  width: 27px;
  height: 27px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  display: flex
}

.avatar-upload2 .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload2 .avatar-edit input+label:after {
  /* content: "\f040"; */
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload2 .avatar-preview {
  width: 141px;
  height: 140px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload2 .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile_card {
  background: #222;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 300px;
  text-align: center;
  width: 300px;
  word-break: break-word;
}

.profile_card h2 {
  margin: 0 0 10px;
}

.profile_card p {
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
  font-style: italic;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

.profile_card p::before {
  content: open-quote;
}

.profile_card p::after {
  content: close-quote;
}