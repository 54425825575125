
  
  .emoji-picker {
    position: absolute;
    bottom: 50px;
    display: flex;
    gap: 31px;
    z-index: 10;
    cursor: pointer;
    z-index: 0;
    bottom: 78px;
  }
  @media only screen and (max-width: 600px) {
    .emoji-picker {
      position: absolute;
      bottom: 50px;
      display: flex;
      gap: 6px;
      z-index: 10;
      cursor: pointer;
      z-index: 0;
      bottom: 99px;
    }
  }
 
  .emoji-picker .emoji {
    font-size: 2rem;
    transition: transform 0.2s ease-in-out;
    outline: none;
    border: none;
    background-color:transparent;
    cursor: pointer;
  }
  
  .emoji-picker .emoji:hover {
    transform: scale(1.2);
  }
  
  .emoji-container {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .animated-emoji {
    position: absolute;
    font-size: 2rem;
    animation: floatUpToTop 4s ease-out forwards;
    will-change: transform, opacity;
  }
  
  @keyframes floatUpToTop {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateY(-100vh) scale(1.2);
      opacity: 0;
    }
  }
  