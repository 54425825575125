/* AutoGrowTextarea.css */
.textarea-container {
    width: 86%;
  }
  
  .textarea-wrapper {
    display: inline-block;
    max-height: 55px; /* Set a maximum height for the textarea */
    overflow-y: auto; /* Display scrollbar when content exceeds maximum height */
    border-radius: 8px; /* Set the border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Apply the box shadow */
    width: 100%;
  }
    /* Custom scrollbar styles for Webkit-based browsers */
    .textarea-wrapper::-webkit-scrollbar {
      width: 8px;
    }
    
    .textarea-wrapper::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    
    .textarea-wrapper::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  textarea {
    width: 100%;
    resize: none;
    border: none; /* Remove default border */
    padding: 10px;
    font-size: 16px;
    line-height: 30px; /* Set the line height */
    overflow: hidden;
    outline: none;
    box-sizing: border-box; /* Include padding and border in total width and height calculation */
  }
  
  .autoicon-container {
    position: absolute;
    bottom: 23px;
    right: 6px;
    background-color: #18b818;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    align-items: center;
    text-align: center;
    padding: 3px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  }
  .autoicon-container2 {
    position: absolute;
    bottom: 23px;
    right: 6px;
    background-color:whitesmoke;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    align-items: center;
    text-align: center;
    padding: 3px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  }
  /* @media (max-width: 660px) {
    .textarea-container {
        position: fixed;
        bottom: -3px;
        right: 2%;
        width: 96%;
    }
    .autoicon-container {
        position: absolute;
        bottom: 15px;
        right: -2px;
        background-color: #18b818;
        border-radius: 5px;
        height: 30px;
        width: 30px;
        align-items: center;
        text-align: center;
        padding: 3px;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
    }
    .autoicon-container2 {
        position: absolute;
        bottom: 15px;
        right: -2px;
        background-color: whitesmoke;
        border-radius: 5px;
        height: 30px;
        width: 30px;
        align-items: center;
        text-align: center;
        padding: 3px;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
    }
  } */