.bottom-right {
    position: fixed;
    bottom: 20px; /* Distance from the bottom of the screen */
    right: 20px;  /* Distance from the right of the screen */
    z-index: 1000; /* Ensures it stays above most other elements */
  }
  
  .icon-container {
    position: relative; /* Needed for the hover text positioning */
    width: 50px;       /* Adjust the size of the circle */
    height: 50px;
    background-color: #007bff; /* Background color for the button */
    color: white;             /* Icon color */
    border-radius: 50%;       /* Makes it round */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;          /* Pointer cursor on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow */
    transition: background-color 0.3s ease;
  }
  
  .icon-container:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
  
  .icon {
    font-size: 24px; /* Adjust icon size */
  }
  
  .hover-text {
    position: absolute;
    bottom: 60px; /* Position above the button */
    /* left: 50%; */
    transform: translateX(-50%);
    background-color: #000; /* Background for the tooltip */
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    pointer-events: none; /* Prevent interaction with the tooltip */
    transition: opacity 0.3s ease;
  }
  
  .icon-container:hover .hover-text {
    opacity: 1; /* Show on hover */
  }
  