/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
} */

.container2 {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
}

.container2 h1 {
    font-size: 2.5em;
    color: #2c3e50;
    margin-bottom: 20px;
}

.container2 p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.features, .privacy {
    text-align: left;
    margin-top: 30px;
}

.features h2, .privacy h2 {
    font-size: 1.8em;
    color: #3498db;
    margin-bottom: 15px;
}

.features ul, .privacy ul {
    list-style: none;
    padding: 0;
}

.features ul li, .privacy ul li {
    font-size: 1.1em;
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;
}

.features ul li:before, .privacy ul li:before {
    content: "\2713";
    color: #27ae60;
    position: absolute;
    left: 0;
}

.cta {
    margin-top: 30px;
}

.cta button {
    background-color: #3498db;
    color: #fff;
    font-size: 1.2em;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta button:hover {
    background-color: #2980b9;
}

.privacy p {
    margin-bottom: 15px;
}