body {
    font-family: "Poppins", Arial, sans-serif;
    margin: 0;
    padding: 0;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: linear-gradient(to right, #ff7e5f, #feb47b); */
    color: white;
    overflow: hidden;
  }
  
  .container {
    position: relative;
    width: 100%;
    /* max-width: 500px; */
    max-width: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  #question{
    font-family: oswald;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    color: white;
  }
  .question-card {
    width: 100%;
    padding: 25px;
    text-align: center;
    /* background: linear-gradient(145deg, #e0f7fa, #ffffff); */
    /* border-radius: 20px; */
    /* box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #01579b;
    /* border: 5px solid #81d4fa; */
    font-weight: 900;
    aspect-ratio: 8 / 5;
    --bg: hsl(330 80% calc(90% - (0 * 10%)));
    --accent: hsl(352.21deg 100% 50.91%);
    transition: background 0.2s;
    background: radial-gradient(circle at top left, var(--accent), transparent 99%), var(--bg);
    margin: 0;
    /* position: relative; */
    /* overflow: hidden; */
    border-radius: 1.5rem;
  }
  
  .options-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* margin-top: 20px; */
    width: 100%;
  }
  
  .option-card {
    flex: 1;
    padding: 15px;
    text-align: center;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .option-card:hover {
    background: #e3f2fd;
    border-color: #007bff;
  }
  
  .option-card.selected {
    background: #007bff;
    color: #ffffff;
    border-color: #007bff;
  }

  .option-card.selected_right {
    background: #0fc70fe8;
    color: #ffffff;
    border-color: #0fc70fe8;
}
  .option-card.selected_wrong {
    background: #fc1a1abf;
    color: #ffffff;
    border-color: #fc1a1abf;
}

  .custom-radio {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
    border: 3px solid #0288d1;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  
  .option-card.selected .custom-radio {
    background-color: #ffffff;
  }
  
  .next-btn {
    margin-top: 30px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    /* background: #039be5; */
    background: #030a0d;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 200px;
  }
  /* .disable_next_btn{
    margin-top: 30px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: #63c5f4;
    border: none;
    border-radius: 25px;
    cursor:not-allowed;
    transition: transform 0.2s ease-in-out, background 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 200px;
  }
   */

   .disable_next_btn {
    margin-top: 30px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: #9a9ea9; /* Grayish background for disabled look */
    border: none;
    border-radius: 25px;
    cursor: not-allowed;
    transition: transform 0.2s ease-in-out, background 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 200px;
    opacity: 0.6; /* Makes it visually appear disabled */
}

/* Optional: Hover effect for better user feedback */
.disable_next_btn:hover {
    background: #9a9ea9;
    transform: none;
    box-shadow: none;
}

  .next-btn:hover {
    transform: scale(1.05);
    background: #030a0d;
  }
  
  @keyframes slide-in-left {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slide-out-left {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  
  .slide-in-left {
    animation: slide-in-left 0.5s ease-out forwards;
  }
  
  .slide-out-left {
    animation: slide-out-left 0.5s ease-in forwards;
  }
  
/* 
  article {
    --hover: 0;
  }
  
  article:hover {
    --hover: 1;
  }
  
  figure {
    width: 500px;
    aspect-ratio: 8 / 5;
    --bg: hsl(330 80% calc(90% - (var(--hover) * 10%)));
    --accent: hsl(280 80% 40%);
    transition: background 0.2s;
    background:
      radial-gradient(circle at top left, var(--accent), transparent 75%),
      var(--bg);
    margin: 0;
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
  }
  
  figure:after {
    content: "";
    position: absolute;
    width: 20%;
    aspect-ratio: 1;
    border-radius: 50%;
    bottom: 0%;
    left: 10%;
    background: linear-gradient(-65deg, var(--bg) 50%, var(--accent) 50%);
    filter: blur(25px);
    transform:
      translateX(calc(var(--hover) * 15%))
      scale(calc(1 + (var(--hover) * 0.2)));
    transition: transform 0.2s, background 0.2s;
  }
   */
   .card {
    /* width: 395px; */
    /* background: white; */
    /* border-radius: 15px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    /* text-align: center; */
    position: relative;
    /* padding-top: 50px; */
    align-items: center;
    display: flex
;
    justify-content: center;
}

   .profile-img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border: 4px solid white;
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 23;
    cursor: pointer;
}