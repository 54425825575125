.container_image {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    overflow: hidden; /* Prevent the image from overflowing the container */
  }
  
  .bouncing-image {
    width: 150px;
    height: auto;
    animation: floatAndBounce 4s ease-in-out infinite;
  }
  
  @keyframes floatAndBounce {
    0% {
      transform: translate(0, 0); /* Starting position */
    }
    25% {
      transform: translate(-15px, -20px); /* Floating left and up */
    }
    50% {
      transform: translate(15px, 0); /* Floating right and back to the center vertically */
    }
    75% {
      transform: translate(-10px, 10px); /* Floating left and down */
    }
    100% {
      transform: translate(0, 0); /* Back to the center */
    }
  }
  .emoji {
    font-size: 65px; /* Adjust size */
    /* width: 100px; 
    height: 100px;
    display: inline-block;
    text-align: center;  */
}