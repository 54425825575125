/* General styles */
body {
    font-family: 'Poppins', sans-serif;
  }
  .container {
    /* padding-top: 50px; */
    text-align: center;
  }
  .show-modal {
    background: linear-gradient(to right, #33a3ff, #0675cf, #49a6fd);
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .show-modal:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  /* Modal Animation */
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(255 0 0 / 54%); */
    overflow: auto;
    padding-top: 60px;
    animation: fadeIn 1.6s ease-out;
  }
  .modal2 {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(255 0 0 / 54%); */
    overflow: auto;
    padding-top: 60px;
    animation: fadeIn 1.6s ease-out;
  }
 
  /* .modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    width: 34%;
    position: relative;
    max-height: 634px;
    overflow-x: scroll;
  } */
  .modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    width: 58%;
    position: relative;
    max-height: 634px;
    max-width: 481px;
    overflow-x: scroll;
    overflow-y: auto; /* Enable vertical scrolling as well */
    animation: fadeIn 0.6s ease-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Custom scrollbar styles */
.modal-content::-webkit-scrollbar {
    height: 8px; /* Horizontal scrollbar height */
    width: 8px; /* Vertical scrollbar width */
}

.modal-content::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 5px; /* Rounded corners for the track */
}

.modal-content::-webkit-scrollbar-thumb {
    background: #888; /* Dark grey scrollbar thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker grey on hover */
}

/* Firefox scrollbar styling */
.modal-content {
    scrollbar-width: thin; /* Thinner scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
    animation: fadeIn 0.6s ease-out;
    overflow-x: hidden;
}

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 25px;
    background: #33a3ff;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .close:hover {
    background-color: #0675cf;
  }
  .modal-body {
    text-align: left;
    padding: 20px;
    padding-top: 2px;
  }
  /* Title and description */
  .title {
    font-size: 33px;
    font-weight: 700;
    color: #026fd4;
    margin-bottom: 10px;
    text-transform: none
  }
  .description {
    color: #9A9EA9;
    font-size: 16px;
    margin-bottom: 20px;
  }
  /* Input fields with icons */
  .form-group {
    margin-bottom: 20px;
    position: relative;
    margin-top: 6px;
  }
  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #777;
  }
  .form-control {
    width: 80%;
    padding: 10px 40px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #eee;
    transition: all 0.3s ease;
    outline: none;
  }
  .form-control:focus {
    border-color: #33a3ff;
    box-shadow: 0 0 10px rgba(51, 163, 255, 0.5);
  }
  .checkbox {
    margin-top: 10px;
  }
  .checkbox input {
    margin-right: 5px;
  }
  .forgot-pass {
    font-size: 18px;
    color: #7F7FD5;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }
  .forgot-pass:hover {
    color: #9A9EA9;
    text-decoration: underline;
  }
  /* Button */
  .btn {
    background: linear-gradient(to right, #33a3ff, #0675cf, #4cd5ff);
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px;
    width: 98%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn:hover {
    background: linear-gradient(to right, #33a3ff, #0675cf, #49a6fd);
  }
  @media only screen and (max-width: 480px) {
    .modal-content {
      width: 90%;
      top: -53px;
    }
    .modal-body {
      padding: 20px;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      color: #026fd4;
      text-transform: none;
      margin-bottom: 10px;
  }
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(255 0 0 / 54%); */
    overflow: auto;
    padding-top: 50%;
    animation: fadeIn 0.6s ease-out;
  }
  .form-control {
    width: 71%;
    padding: 10px 40px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #eee;
    transition: all 0.3s ease;
    outline: none;
}
  }

  .input-icon_eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #777;
    cursor: pointer;
}