body {
  font-family: 'Arial', sans-serif;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(to right, #ff7e5f, #feb47b); */
  color: white;
  /* overflow: hidden; */
}

.full_logo {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 117px;
  width: 111px;
  height: 111px;
  /* width: 120px;
  height: 120px; */
  align-items: center;
  justify-content: center;
}

.text-container {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.5;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: -45px;
  position: relative;
}

.sentence {
  opacity: 0;
  animation: fadewelcomeIn 1s ease-in-out forwards;
  font-size: 30px;
}

@keyframes fadewelcomeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.word {
  display: inline-block;
  margin: 0 5px;
}


.animated-button {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  background-color: #0000FF;
  color: white;
  border: 2px solid transparent;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  outline: none;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.10s ease;
}

.animated-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.animated-button:hover {
  background-color: #0000FF;
  color: #fff;
  border-color: #0000FF;
}

.animated-button:hover::before {
  width: 0;
  height: 0;
}

.animated-button:focus {
  outline: none;
}

.animated-button:active {
  transform: scale(0.98);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}