.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.24);
  z-index: 1000;
}

.button-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
}

/* Menu Buttons */
.menu {
  display: flex;
  gap: 10px;
  margin-right: 24px;
}

.menu .btn1 {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
  transition: background-color 0.3s ease;
}

.menu .btn1:hover {
  background-color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .button-bar {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .menu {
    margin-top: 5px;
    gap: 5px;
  }
}
