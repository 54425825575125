.share-container {
    position: relative;
    display: inline-block;
  }
  
  .share-icon {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    width: 42px;
  }
  
  .sharepopup {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .sharepopup-option {
    display: block;
    padding: 10px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sharepopup-option:hover {
    background: #f1f1f1;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  