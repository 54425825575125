.top-right {
    position: fixed;
    top: 20px;   /* Distance from the top of the screen */
    /* right: 20px;  */
    left: 20px;
    z-index: 1000; /* Ensures it stays above most other elements */
  }
  
  .icon-container {
    position: relative; /* Needed for tooltip positioning */
    width: 50px;       /* Adjust the size of the circle */
    height: 50px;
    background-color: #0000FF; /* Background color for the button */
    color: white;             /* Icon color */
    border-radius: 50%;       /* Makes it round */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;          /* Pointer cursor on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow */
    transition: background-color 0.3s ease;
  }
  
  .icon-container:hover {
    background-color: #0000FF; /* Change background color on hover */
  }
  
  .icon {
    font-size: 24px; /* Adjust icon size */
  }
  
  .notification-badge {
    position: absolute;
    top: -5px;   /* Position above the top of the icon */
    right: -5px; /* Position to the right of the icon */
    background-color: red; /* Red background */
    color: white;
    font-size: 12px; /* Font size of the badge text */
    width: 20px;    /* Width of the badge */
    height: 20px;   /* Height of the badge */
    border-radius: 50%; /* Circular badge */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  