.form-container {
    text-align: center;
    width: 100%;
    /* max-width: 500px; */
  }
  .toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .toggle-button:hover {
    background-color: #0056b3;
  }
  .form-fields {
    margin-top: 20px;
  }
  .form-fields input {
    margin: 10px 5px;
    padding: 10px;
    width: calc(50% - 12px);
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .add-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .add-button:hover {
    background-color: #218838;
  }
  .question-list {
    margin-top: 20px;
    width: 100%;
    /* max-width: 500px; */
  }
  .question-list ul {
    list-style: none;
    padding: 0;
  }
  .question-create-card{
    background: #ffffff;
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  .question-create-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .delete:hover {
    background: #cc0000;
  }
  .question {
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
    white-space: break-spaces;
    max-width: 95%;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    
  }
  .options div {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    width: 48%;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: black;
  }
  /* Slide-down fade-in animation */
  @keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  /* Slide-up fade-out animation */
  @keyframes slideUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
  }
  .form-fields {
    margin-top: 20px;
    opacity: 0;
    animation: slideDown 0.5s forwards;
  }
  .form-fields.hide {
    animation: slideUp 0.5s forwards;
  }
  
  @keyframes fadeInSlideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .new-card {
    animation: fadeInSlideUp 0.6s ease-in-out;
  }
  

  .ai-button {
    padding: 8px 16px;  /* Smaller padding */
    font-size: 14px;     /* Smaller font size */
    color: #fff;
    background-color: #00bcd4; /* AI Color */
    border: 2px solid #00bcd4;
    border-radius: 12px;   /* Slightly rounded corners */
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 5px 10px rgba(0, 188, 212, 0.3);  /* Smaller shadow */
}
.ai-button:hover {
    background-color: #0097a7;
    border-color: #0097a7;
    box-shadow: 0 5px 15px rgba(0, 188, 212, 0.5);
    transform: translateY(-2px);
}
.ai-button:focus {
    outline: none;
}
.ai-button:active {
    background-color: #007b80;
    transform: translateY(1px);
}
  
  
  
  
  
  
  
  
  
  