
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.dots {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.dot {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    animation: bounce 1.2s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
}

.text {
    font-size: 20px;
    color: red;
    text-transform: uppercase;
    letter-spacing: 2px;
}