.slider-container {
    width: 100%;
    overflow-x: auto;
    padding: 20px 0;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .slider-container::-webkit-scrollbar {
    display: none;
  }
  
  .slider {
    display: flex;
    gap: 15px;
    padding: 10px;
  }
  
.anicard {
    width: 350px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    background: white;
  }
  
  .anicard:hover {
    transform: scale(1.05) translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .anicard-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .anicard:hover .anicard-image {
    transform: scale(1.1);
  }
  
  .anicard-content {
    padding: 15px;
    text-align: center;
  }
  
  .anicard-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .anicard-description {
    font-size: 1rem;
    color: #666;
  }
  


  .slider-card-image {
    width: 37%;
    height: 138px;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    align-items: center;
    border-top-right-radius: 10px;
    justify-content: center;
    display: flex
;
    margin-left: 102px;
}

#main-slider-container{
  height: 168px;
}



.slider-icon.left, .slider-icon.right {
  background: rgb(218, 13, 13);
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}