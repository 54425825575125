
.modal-container_profile {
    position: relative;
  }
  .model_profile_img{
    transition: transform 0.3s ease;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 120px;
    height: 120px;
  }
  .modal-trigger_profile {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-trigger_profile:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .modal-overlay_profile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .modal-content_profile {
    position: fixed;
    top: calc(20px + 40px + 10px); /* Below the trigger image */
    right: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.3s ease-out;
    max-width: 400px;
    width: 90%;
    margin: 0 auto;
  }
  
  .modal-arrow_profile {
    position: absolute;
    top: -8px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
  }
  
  /* Water Drop Animation */
  @keyframes waterDrop {
    0% {
      transform: scale(0) translateY(-20px);
      opacity: 0;
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }
  
  .modal-content_profile {
    animation: waterDrop 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  /* Ripple Effect */
  @keyframes ripple {
    to {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  .modal-overlay_profile::before {
    content: '';
    position: absolute;
    top: calc(20px + 40px);
    right: 20px;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 0.6s ease-out;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .modal-trigger_profile {
      right: 15px;
      top: 15px;
      width: 35px;
      height: 35px;
      z-index: 1;
    }
  
    .modal-content_profile {
      right: 9px;
      left: 0px;
      width: calc(100% - 20px);
      max-width: none;
      /* margin-top: 60px; */
    }
  
    .modal-overlay_profile::before {
      top: calc(15px + 35px);
      right: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content_profile {
      padding: 15px;
      border-radius: 15px;
    }
    
    .modal-arrow_profile {
      right: 8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 11px solid rgba(255, 255, 255, 0.9);
    }
  }
  
  /* Optional: Add water-like texture */
  .modal-content_profile::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.1) 100%
    );
    pointer-events: none;
    border-radius: 20px;
    z-index: -1;
  }

  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 16px auto;
    display: flex;
    justify-content: center;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    right: 53px;
    z-index: 1;
    top: 0px;
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 27px;
    height: 27px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    align-items: center;
    display: flex
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .avatar-upload .avatar-edit input + label:after {
    /* content: "\f040"; */
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-upload .avatar-preview {
    width: 141px;
    height: 140px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .profile_card {
    background: #222;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    text-align: center;
}
.profile_card h2 {
    margin: 0 0 10px;
}
.profile_card p {
    margin: 0;
    font-size: 14px;
    opacity: 0.8;
    font-style: italic;
    quotes: "\201C" "\201D" "\2018" "\2019";
}
.profile_card p::before {
    content: open-quote;
}
.profile_card p::after {
    content: close-quote;
}


.apple-button {
  background: linear-gradient(to bottom, #fafafa, #e0e0e0);
  border: 1px solid #d1d1d6;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.apple-button:hover {
  background: linear-gradient(to bottom, #fefefe, #e4e4e4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.apple-button:active {
  background: linear-gradient(to bottom, #e4e4e4, #d1d1d6);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 18px;
  height: 18px;
  /* fill: #333; */
}